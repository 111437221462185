import './About.scss';
import Sofa from '../../images/sofa_4.jpg'
import Map from '../../components/Map/Map';

export default function About() {
    return (
        <div className='about'>
            <section className='info section'>
                <div className='text'>
                    <h1>MITRAM</h1>
                    <p>
                        Tudo começa com uma ideia.
                        Talvez você queira abrir um negócio.
                        Talvez você queira transformar um passatempo em algo mais sério.
                        Ou talvez você tenha um projeto criativo para divulgar ao mundo.
                        Seja o que for, o modo de contar sua história on-line faz toda a diferença.
                    </p>
                </div>
                <div className='image'>
                    <img src={Sofa} />
                </div>
            </section>
            <section className='section'>
                <Map />
            </section>
        </div>
    );
}