import { Outlet } from "react-router-dom";
import Header from "../../components/Header/Header.js";
import Footer from "../../components/Footer/Footer.js";

export default function Layout({ pathname }) {
    return (
        <>
            <Header currentPath={pathname} />
            <Outlet />
            <Footer />
        </>
    );
}