import './Footer.scss';

export default function Footer() {
    return (
        <footer className='footer'>
            <p>
                © 2024, MITRAM | Todos os direitos reservados.
            </p>
            <p>
                A MITRAM não se responsabiliza por eventuais erros publicados no website.
            </p>
        </footer>
    );
}