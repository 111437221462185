import "./Map.scss";

export default function Map() {
    return (
        <>
            <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2998.234491332473!2d-8.38764372394567!3d41.28200077131336!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd248cbb495cd51f%3A0x6efee9366baa2a6!2sTv.%20das%20Amoras%20250%2C%20Meixomil!5e0!3m2!1spt-PT!2spt!4v1720561531184!5m2!1spt-PT!2spt"
                width="100%"
                height="500"
                className="map"
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                title="Localização Mitram"></iframe>
        </>
    );
}