import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";

import Layout from "./pages/Layout/Layout.js";
import About from "./pages/About/About.js";
import Contact from "./pages/Contact/Contact.js";
import Collection from "./pages/Collection/Collection.js";
import Home from "./pages/Home/Home.js";
import './App.css';

function App() {
  const location = useLocation();
  const { pathname } = location;
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Layout pathname={pathname} />}>
          <Route index element={<Home />} />
          <Route path="sobre" element={<About />} />
          <Route path="colecao" element={<Collection />} />
          <Route path="contato" element={<Contact />} />
          <Route path="*" element={<Home />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
