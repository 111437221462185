import { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import './Header.scss';
import Logo from "../../images/mitram-v2.png"
import LogoWhite from "../../images/mitram-v2-white.png"




export default function Header({ currentPath }) {
    const [menuIsOpened, setMenuIsOpened] = useState(false);
    const [alternateTheme, setAlternateTheme] = useState(false);


    const openMenu = (e) => {
        setMenuIsOpened(true);
    }

    const closeMenu = (e) => {
        setMenuIsOpened(false);
    }

    useEffect(() => {
        if (currentPath === "/") {
            setAlternateTheme(true);
        } else {
            setAlternateTheme(false);
        }
    }, [currentPath])

    return (
        <header>
            <nav className={alternateTheme ? "header-desktop alternate" : "header-desktop"}>
                <Link to="/" className="logo">
                    {
                        alternateTheme ?
                            <img src={LogoWhite} alt="Logo Mitram" />
                            :
                            <img src={Logo} alt="Logo Mitram" />
                    }
                </Link>
                <ul className="links">
                    <li>
                        <Link to="/colecao">Coleção</Link>
                    </li>
                    <li>
                        <Link to="/contato">Contato</Link>
                    </li>
                    <li>
                        <Link to="/sobre">Sobre</Link>
                    </li>
                    <li>
                        <a href="https://www.instagram.com/mitram.pt"
                            aria-label="Mitram Instagram @mitram.pt"
                            aria-hidden="true"
                            target="_blank" rel="noreferrer" className="instagram-icon" />
                    </li>
                </ul>
            </nav>
            <nav className={`header-mobile ${alternateTheme ? 'alternate' : ''} ${menuIsOpened ? 'open' : ''}`}>
                <div className={menuIsOpened ? "options open" : "options"}>
                    <Link to="/" className="logo">
                        {
                            alternateTheme && !menuIsOpened ?
                                <img src={LogoWhite} alt="Logo Mitram" />
                                :
                                <img src={Logo} alt="Logo Mitram" />
                        }
                    </Link>
                    {
                        menuIsOpened ?
                            <button
                                className="close-icon"
                                onClick={closeMenu}
                                aria-label="Mitram Instagram @mitram.pt"
                                aria-hidden="true" />
                            :
                            <button
                                className="menu-icon"
                                onClick={openMenu}
                                aria-label="Mitram Instagram @mitram.pt"
                                aria-hidden="true" />
                    }

                </div>
                {
                    menuIsOpened &&
                    <ul className="links show">
                        <li>
                            <Link onClick={closeMenu} to="/colecao">Coleção</Link>
                        </li>
                        <li>
                            <Link onClick={closeMenu} to="/contato">Contato</Link>
                        </li>
                        <li>
                            <Link onClick={closeMenu} to="/sobre">Sobre</Link>
                        </li>
                        <li>
                            <a href="https://www.instagram.com/mitram.pt"
                                onClick={closeMenu}
                                aria-label="Mitram Instagram @mitram.pt"
                                aria-hidden="true"
                                target="_blank" rel="noreferrer" className="instagram-icon" />
                        </li>
                    </ul>
                }
            </nav>
            <div className={menuIsOpened ? "mobile-fade" : ""} onClick={closeMenu}>

            </div>
        </header>
    );
}