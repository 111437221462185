import './Faq.scss';
import FaqItem from '../FaqItem/FaqItem';

export default function Faq() {

    const questions = [
        {
            id: 0,
            question: "Quais são os vossos produtos mais populares?",
            answer: "Tudo começa com uma ideia. Talvez você queira abrir um negócio. Talvez você queira transformar um passatempo em algo mais sério. Ou talvez você tenha um projeto criativo para compartilhar com o mundo. Seja o que for, o modo de contar sua história on-line faz toda a diferença. "
        },
        {
            id: 1,
            question: "Os produtos são ecológicos?",
            answer: "Tudo começa com uma ideia. Talvez você queira abrir um negócio. Talvez você queira transformar um passatempo em algo mais sério. Ou talvez você tenha um projeto criativo para compartilhar com o mundo. Seja o que for, o modo de contar sua história on-line faz toda a diferença. "
        },
        {
            id: 2,
            question: "Oferecem a opção de entrega gratuita?",
            answer: "Tudo começa com uma ideia. Talvez você queira abrir um negócio. Talvez você queira transformar um passatempo em algo mais sério. Ou talvez você tenha um projeto criativo para compartilhar com o mundo. Seja o que for, o modo de contar sua história on-line faz toda a diferença. "
        }
    ]

    return (
        <div className='faq'>
            <span className='title'>Perguntas Frequentes</span>
            <div className='questions'>
                {
                    questions && questions.map((question) => {
                        return (
                            <FaqItem question={question} key={question.id} />
                        )
                    })
                }
            </div>
        </div>
    );
}