import { useState } from 'react';
import './ContactForm.scss';

export default function ContactForm() {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        message: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log("Submited!")
    }

    return (
        <div className='contact-form'>
            <div className='content'>
                <h2>
                    Entre em contato
                </h2>
                <p>
                    Para mais informações ou orçamentos, temos uma equipa preparada para o auxiliar.
                </p>
                <p>
                    Estamos à disposição para responder às suas perguntas e fornecer o suporte necessário.
                </p>
            </div>
            <form className="form" onSubmit={handleSubmit}>
                <div className='form-input'>
                    <label htmlFor="name">Nome</label>
                    <input
                        type="text"
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        required />
                </div>
                <div className='form-input'>
                    <label htmlFor="email">E-mail</label>
                    <input
                        type="text"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required />
                </div>
                <div className='form-input'>
                    <label htmlFor="subject">Assunto</label>
                    <input
                        type="email"
                        id="subject"
                        name="subject"
                        value={formData.subject}
                        onChange={handleChange}
                        required />
                </div>
                <div className='form-input'>
                    <label htmlFor="message">Mensagem</label>
                    <textarea
                        id="message"
                        name="message"
                        rows="4"
                        value={formData.message}
                        onChange={handleChange}
                        required />
                </div>
                <button type="submit">Enviar</button>
            </form>
        </div>
    );
}