import './Home.scss';
import Sofa from '../../images/sofa_3.jpg'
import Faq from "../../components/Faq/Faq";


export default function Home() {
    return (
        <div className='home'>
            <section className="banner">
                <img src={Sofa} alt="Sofa 4 - Mitram" />
            </section>
            <section className="slogan">
                <div className="slogan-text">
                    <span className='text'>DESIGN</span>
                    <span className='text'>CONFORT</span>
                    <span className='text'>MINIMALISM</span>
                </div>
            </section>
            <section className='faqs'>
                <Faq />
            </section>
            { /*
            // Catálogos
            // Perguntas frequentes
            // Newsletter - Form de Contacto */}
        </div>
    );
}