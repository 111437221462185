import { useState, useRef, useEffect } from 'react';
import './FaqItem.scss';

export default function FaqItem({ question }) {

    const contentRef = useRef(null);
    const [maxHeight, setMaxHeight] = useState("0px");
    const [isOpen, setIsOpen] = useState(false);

    const handleQuestionClick = () => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        console.log(contentRef.current.scrollHeight)
        setMaxHeight(isOpen ? `${contentRef.current.scrollHeight + 8}px` : '0px');
    }, [isOpen]);

    return (
        <div
            className={isOpen ? "question show" : "question"}>
            <button
                className='title'
                onClick={() => handleQuestionClick()}>
                <span>
                    {question.question}
                </span>
                <div className='arrow' />
            </button>
            <span
                className="answer"
                ref={contentRef}
                style={{ maxHeight }}>
                {question.answer}
            </span>
        </div>
    )


}