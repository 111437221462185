import ContactForm from "../../components/ContactForm/ContactForm";
import Map from "../../components/Map/Map";
import "./Contact.scss";

export default function Contact() {
    return (
        <div className="contact">
            <section className='section'>
                <ContactForm />
            </section>
            <section className='schedule section'>
                <h2>Paços de Ferreira</h2>
                <div className="scheduling">
                    <span><b>Horário</b></span>
                    <span>Segunda a Sexta-feira</span>
                    <span>09h - 17h</span>
                </div>
            </section>
            <section className='section'>
                <Map />
            </section>
        </div>
    );
}